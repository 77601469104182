import React, { FC, useState, useEffect, useMemo } from 'react';
import { Range, RangeProps } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../../../../components/SearchResults/Widget/components/Widget';
import { ProductFacet } from '../ProductFacet';

import { st, classes } from './ProductFacetsPrice.st.css';
import { useSearchEnvironment } from '../../../../components/SearchResults/Widget/hooks';

interface ProductFacetsPriceProps {
  minPrice: number;
  maxPrice: number;
  selectedMinPrice?: number;
  selectedMaxPrice?: number;
  onFacetsFilterChange: IWidgetProps['onFacetsFilterChange'];
}

export const ProductFacetsPrice: FC<ProductFacetsPriceProps> = ({
  minPrice,
  maxPrice,
  selectedMinPrice,
  selectedMaxPrice,
  onFacetsFilterChange,
}) => {
  const { isMobile } = useEnvironment();
  const { currency, locale } = useSearchEnvironment();
  const { t } = useTranslation();

  // Represents values on the range where minimum and maximum should be unrestricted
  // by the user and the component range should naturally grow and shrink.
  const naturalMin = Math.floor(minPrice);
  const naturalMax = Math.ceil(maxPrice);

  const min = useMemo(
    () =>
      Math.min(
        naturalMin,
        selectedMinPrice ?? +Infinity,
        selectedMaxPrice ?? +Infinity,
      ),
    [naturalMin, selectedMinPrice, selectedMaxPrice],
  );

  const max = useMemo(
    () =>
      Math.max(
        naturalMax,
        selectedMinPrice ?? -Infinity,
        selectedMaxPrice ?? -Infinity,
      ),
    [naturalMax, selectedMinPrice, selectedMaxPrice],
  );

  const [value, setValue] = useState<[number?, number?]>([
    selectedMinPrice,
    selectedMaxPrice,
  ]);

  useEffect(() => {
    setValue([selectedMinPrice, selectedMaxPrice]);
  }, [selectedMinPrice, selectedMaxPrice]);

  const rangeValue: RangeProps['value'] = [value[0] ?? min, value[1] ?? max];

  const handleChange: RangeProps['onChange'] = ([newMinPrice, newMaxPrice]) => {
    setValue([newMinPrice, newMaxPrice]);
  };

  const handleAfterChange = () => {
    const newMinPrice = value[0] !== naturalMin ? value[0] : undefined;
    const newMaxPrice = value[1] !== naturalMax ? value[1] : undefined;

    if (newMinPrice !== selectedMinPrice || newMaxPrice !== selectedMaxPrice) {
      onFacetsFilterChange({
        price: {
          min: newMinPrice,
          max: newMaxPrice,
        },
      });
    }
  };

  return (
    <ProductFacet title={t('searchResults.facets.priceTitle')}>
      <Range
        className={st(classes.root, { mobileView: isMobile })}
        min={min}
        max={max}
        value={rangeValue}
        onChange={handleChange}
        onAfterChange={handleAfterChange}
        format={{
          style: 'currency',
          currency: currency!,
          locale,
        }}
      />
    </ProductFacet>
  );
};
