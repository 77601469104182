import React, { FC, useMemo } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Divider, RadioButton, RadioButtonGroup } from 'wix-ui-tpa';

import { IWidgetProps } from '../../../components/SearchResults/Widget/components/Widget';
import {
  CommonForumFacetOptions,
  ContentTypeOptions,
  ContentTypeOptionsType,
} from './ForumFacets.types';
import {
  getActiveType,
  getTypeCounts,
  getListTotals,
  getActiveCategory,
} from './ForumFacets.utils';
import { st, classes } from './ForumFacets.st.css';

interface ForumFacetsProps {
  facets: IWidgetProps['facets'];
  facetFilters: IWidgetProps['facetFilters'];
  onFacetsFilterChange: IWidgetProps['onFacetsFilterChange'];
  onFacetsFilterReset: IWidgetProps['onFacetsFilterReset'];
}

const ForumFacets: FC<ForumFacetsProps> = ({
  facets,
  facetFilters,
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { contentType, categoryTitle } = facets;

  const activeType = useMemo(() => getActiveType(facetFilters), [facetFilters]);
  const activeCategory = useMemo(
    () => getActiveCategory(facetFilters),
    [facetFilters],
  );
  const currentTypeCounts = useMemo(
    () => getTypeCounts(contentType),
    [contentType],
  );
  const categoryFacets = categoryTitle ?? [];

  const hasCategories = categoryFacets.length > 0;

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      <div className={classes.subtitle}>
        {t('searchResults.tabs.label.forums.filter.type')}
      </div>

      <RadioButtonGroup
        withSpacing
        name="contentType"
        value={activeType}
        onChange={(value: ContentTypeOptionsType) => {
          if (value === CommonForumFacetOptions.All) {
            onFacetsFilterChange({
              contentType: undefined,
              categoryTitle: undefined,
            });
          } else {
            onFacetsFilterChange({
              contentType: value,
              categoryTitle: undefined, // Should reset current category, because it's unknown if category includes new type documents
            });
          }
        }}
      >
        <RadioButton
          data-hook="forum-facets-type-all"
          className={classes.itemLabel}
          value={ContentTypeOptions.All}
        >
          {t('searchResults.tabs.label.forums.filter.allTypes')}
          <span className={classes.itemCount}>({currentTypeCounts.ALL})</span>
        </RadioButton>
        <RadioButton
          data-hook="forum-facets-type-post"
          className={classes.itemLabel}
          value={ContentTypeOptions.Post}
        >
          {t('searchResults.tabs.label.forums.filter.posts')}
          <span className={classes.itemCount}>({currentTypeCounts.POST})</span>
        </RadioButton>
        <RadioButton
          data-hook="forum-facets-type-comment"
          className={classes.itemLabel}
          value={ContentTypeOptions.Comment}
        >
          {t('searchResults.tabs.label.forums.filter.comments')}
          <span className={classes.itemCount}>
            ({currentTypeCounts.COMMENT})
          </span>
        </RadioButton>
      </RadioButtonGroup>

      {hasCategories && (
        <>
          <Divider className={classes.divider} />

          <div className={classes.subtitle}>
            {t('searchResults.tabs.label.forums.filter.category')}
          </div>

          <RadioButtonGroup
            withSpacing
            name="categoryTitle"
            value={activeCategory}
            onChange={(value: string) => {
              const selectedCategory =
                value !== CommonForumFacetOptions.All ? value : undefined;

              onFacetsFilterChange({
                categoryTitle: selectedCategory,
              });
            }}
          >
            <RadioButton
              data-hook="forum-facets-category-0"
              className={classes.itemLabel}
              value={CommonForumFacetOptions.All}
            >
              {t('searchResults.tabs.label.forums.filter.allCategories')}
              <span className={classes.itemCount}>
                ({getListTotals(categoryFacets)})
              </span>
            </RadioButton>

            {categoryFacets.map(({ label: value, count }, i) => (
              <RadioButton
                key={value}
                value={value}
                data-hook={`forum-facets-category-${i + 1}`}
                className={classes.itemLabel}
              >
                {value}
                <span className={classes.itemCount}>({count})</span>
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </>
      )}
    </div>
  );
};

export default ForumFacets;
