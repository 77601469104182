import * as React from 'react';

import { ImageResizeOptions, ThumbnailImage } from 'wix-ui-tpa';
import { IDocumentImage, IWixImageWithSize } from '@wix/client-search-sdk';

import { IResizedThumbnailProps } from './ResizedThumbnail';

type IFittedThumbnailProps = Pick<
  IResizedThumbnailProps,
  'width' | 'height' | 'image' | 'src' | 'loadingBehavior'
>;

export const FittedThumbnail: React.FC<IFittedThumbnailProps> = (props) => {
  const { width, height, image, ...commonProps } = props;

  const [srcWidth, srcHeight] = isImageWithSize(image)
    ? [image.width, image.height]
    : [width, height];

  return (
    <ThumbnailImage
      {...commonProps}
      aspectRatio={srcWidth / srcHeight}
      fluid
      resize={ImageResizeOptions.contain}
      sourceHeight={srcHeight}
      sourceWidth={srcWidth}
      width={width}
    />
  );
};

const isImageWithSize = (
  image: NonNullable<IDocumentImage>,
): image is IWixImageWithSize =>
  'name' in image && 'height' in image && 'width' in image;
