import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useAppSettings } from '../../../components/SearchResults/Widget/hooks';
import { IWidgetProps } from '../../../components/SearchResults/Widget/components/Widget';
import { ProductFacetsCollections } from './ProductFacetsCollections';
import { ProductFacetsPrice } from './ProductFacetsPrice';
import { isProductCollectionsFacetVisible } from './utils';

import { st, classes } from './ProductFacets.st.css';

interface ProductFacetsProps {
  facets: IWidgetProps['facets'];
  facetFilters: IWidgetProps['facetFilters'];
  onFacetsFilterChange: IWidgetProps['onFacetsFilterChange'];
}

export const ProductFacets: FC<ProductFacetsProps> = ({
  facets,
  facetFilters,
  onFacetsFilterChange,
}) => {
  const { isMobile } = useEnvironment();
  const appSettings = useAppSettings();
  const { collections, price } = facets;
  const { collections: selectedCollections, price: selectedPrice } =
    facetFilters;
  const { isProductPriceVisible } = appSettings;

  const showCollections = isProductCollectionsFacetVisible({
    facets,
    filter: facetFilters,
  });

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      {showCollections && (
        <>
          <ProductFacetsCollections
            collections={collections!}
            selectedCollections={selectedCollections}
            onFacetsFilterChange={onFacetsFilterChange}
          />
          {isProductPriceVisible && <Divider className={classes.divider} />}
        </>
      )}
      {isProductPriceVisible && (
        <ProductFacetsPrice
          minPrice={price?.min ?? 0}
          maxPrice={price?.max ?? 0}
          selectedMinPrice={selectedPrice?.min}
          selectedMaxPrice={selectedPrice?.max}
          onFacetsFilterChange={onFacetsFilterChange}
        />
      )}
    </div>
  );
};
