import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa';
import settingsParams from '../../../components/SearchResults/settingsParams';

import { classes } from './AddToCartButton.st.css';

interface AddToCartButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  inStock: boolean;
}

export const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  onClick,
  inStock,
}) => {
  const settings = useSettings();
  const { t } = useTranslation();

  const label = inStock
    ? settings.get(settingsParams.productsAddToCartButtonText)
    : t('searchResults.products.outOfStock');

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      disabled={!inStock}
      data-hook="add-to-cart-button"
      fullWidth
    >
      {label}
    </Button>
  );
};
